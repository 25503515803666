/* eslint no-useless-concat: 0 */
/* eslint react/jsx-no-target-blank: 0 */
/* eslint jsx-a11y/alt-text: 0 */
import React, {Component} from 'react'
import Redirect from 'react-router-dom/es/Redirect';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import {Form} from 'formsy-semantic-ui-react';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import Container from 'semantic-ui-react/dist/es/elements/Container';
import {confirmAlert} from 'react-confirm-alert'; // Import
import {toast} from 'react-toastify';
import global from "./global"
import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer';
import Loader from 'semantic-ui-react/dist/es/elements/Loader';
export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            redirectToReferrer: false,
            errormessage: '',
            redirect: false,
            showdimmer: false

        }

    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    handleChange = (e, {name, value}) => this.setState({[name]: value})
    sendPassword = () => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to reset your Password?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                this.setState({showdimmer: true})
                var parms = {email: this.state.email}
                var comp = this
                global.getFetch("users/send_reset", parms, this.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        comp.notifySuccess(resp.message)
                        comp.setState({showdimmer: false})


                    } else {
                        comp.notifyError(resp.message)
                        comp.setState({showdimmer: false})

                    }
                })

            }
        })
    }
    handleSubmit = e => {

        var component = this
        fetch("https://apidev.mycontrack.com/auth/login", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    "Client": window.location.host.split(".")[0]
                },
                body: JSON.stringify({
                    email: component.state.email,
                    password: component.state.password
                })
            }
        ).then(function (response) {
            return response.json();
        }).then(function (json) {
             if (json.valid === "N") {
                component.setState({errormessage: json.message})
            } else {
                component.props.onChange(true, json.access_token, JSON.stringify(json.current_user), json.sso_key, json.sso_user, json.system_password)
                 document.getElementById("user").innerHTML = "Current User: " + json.current_user.email +"<br>"+"&nbsp;&nbsp;&nbsp;<a style='color: white;' target='_blank' href='https://apidev.mycontrack.com/showhelp?cc="+window.location.host.split(".")[0]+"'>Help</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a style='color: white;' href='"+window.location.origin+"/?logoff=Y'>Log Off</a>"

                component.setState({redirect: true})
            }
        });

    }

    render() {
        const redirect = this.state.redirect
        if (redirect===true) {
            return <Redirect to="/dashboard"/>
        } else {
            return (
                <div>
                    <Dimmer active={this.state.showdimmer}>
                        <Loader size='small' active={this.state.showdimmer}>Working.....</Loader></Dimmer>

                    <Container>
                        <Grid centered columns={1} style={{marginBottom: "20px"}}>
                            <b><span style={{fontSize: "14px"}}>Please enter your login information below and select 'Submit'. <br/>If you forgot your password, please enter your email address and select "Send New Password".</span></b><br/>
                        </Grid>
                        <Grid centered columns={1}>
                            <Form onValidSubmit={this.handleSubmit}>
                                <Form.Input required name="email" value={this.state.email} onChange={this.handleChange}
                                            label='Email Address' placeholder='Email Address'/>
                                <Form.Input required name="password" value={this.state.password}
                                            onChange={this.handleChange}
                                            type='password' label='Password' placeholder='Password'/>
                                <Button type='submit'>Submit</Button>
                                <div style={{clear: 'both'}}><span
                                    style={{color: 'red'}}>{this.state.errormessage}</span>
                                </div>

                            </Form>
                            <br/><br/>

                        </Grid>
                        <Grid style={{marginTop: "30px"}} centered columns={1}>
                            <Button onClick={this.sendPassword}>Send New Password</Button>

                         </Grid>
                        <Grid style={{marginTop: "30px"}} centered columns={1}>
                            <b><span style={{fontSize: "10px"}}>This software is designed for</span></b>
                        </Grid>
                        <Grid centered columns={1}>
                            <a target="_blank" href="https://www.google.com/chrome/browser/"><img src="images/chromeimage.jpg"/></a>
                        </Grid>
                        <Grid centered columns={1}>
                            <b><span style={{fontSize: "10px"}}>Please disable pop-up blockers</span></b>
                        </Grid>

                    </Container>

                </div>

            )
        }
    }

}



