/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';
import global from "./global"
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import {Form, Dropdown, Checkbox} from 'formsy-semantic-ui-react';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export class FieldEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            template_save: this.props.data.template,
            confirmOpen: false,
            columns: this.props.columns,
            current_user: JSON.parse(sessionStorage.getItem("current_user"))


        };


    }

    handleEditorChange = (e) => {
        var vals = []
        vals.template = e
        this.checkDirty(vals)
        this.setState({data: {template: e}})
    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }

    checkDirty(vals) {
        var savedData = this.state.data
        var comp = this
        Object.keys(vals).map(function (i) {
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false

        })
    }

    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }
    setDirty = (val) => {
        this.isDirty = val
    }


    handleSave = () => {

        this.refs.editForm.submit()

    }
    onValidSubmit = (formData, resetForm, invalidateForm) => {
        this.setState({data: formData})
        var comp = this
        var parms = formData
        parms.id = this.state.recid
        if (this.state.recid === null) {
            global.postFetch("fields/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        } else {
            global.postFetch("fields/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.data.id,
                        data: resp.data,
                      })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }

    render() {
        const {current_user} = this.state
        var modoptions = [{text: "BoilerPlate", value: "BoilerPlate"},
            {text: "Contract", value: "Contract"}, {text: "Department", value: "Department"},
            {text: "User", value: "User"}, {text: "Vendor", value: "Vendor"}, {text: "Vendor Contact", value: "VendorContact"}
        ]
        var itoptions = [{text: "Checkbox", value: "Checkbox"},
            {text: "Dropdown", value: "Dropdown"},
            {text: "Number", value: "Number"},
            {text: "Radio", value: "Radio"},
            {text: "Select", value: "Select"},
            {text: "Text", value: "Input"},
            {text: "TextArea", value: "TextArea"}
        ]
        const panes = [
            {
                menuItem: 'Main',
                pane: (
                    <Tab.Pane key='tab1'>

                        <Form onChange={this.markDirty} ref="editForm" onSubmit={this.onValidSubmit}>
                            <Grid padded={false} columns='equal'>
                                <Grid.Row><Grid.Column width={4}>

                                    <Form.Input
                                        required
                                        name="name"
                                        label="Name"
                                        placeholder="Name"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.name}
                                        value={this.state.data.name}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Name is Required',
                                        }}
                                    />
                                </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Form.Input
                                            required
                                            name="db_name"
                                            label="DB Name"
                                            placeholder="DB Name"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.data.db_name}
                                            value={this.state.data.db_name}
                                            validationErrors={{
                                                isDefaultRequiredValue: 'DB Name is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                    <Form.Checkbox
                                        name="is_active"
                                        label="Field is Active"
                                        defaultValue={this.state.data.is_active}
                                        value={this.state.data.is_active}
                                        defaultChecked={this.state.data.is_active}

                                    />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Form.Checkbox
                                            name="is_required"
                                            label="Field is Required"
                                            defaultValue={this.state.data.is_required}
                                            value={this.state.data.is_required}
                                            defaultChecked={this.state.data.is_required}

                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row><Grid.Column width={4}>

                                    <Form.Input
                                        required
                                        name="label"
                                        label="Label"
                                        placeholder="Label"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.label}
                                        value={this.state.data.label}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Label is Required',
                                        }}
                                    />
                                </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Form.Input
                                            required
                                            name="placeholder"
                                            label="PlaceHolder"
                                            placeholder="PlaceHolder"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.data.placeholder}
                                            value={this.state.data.placeholder}

                                            validationErrors={{
                                                isDefaultRequiredValue: 'PlaceHolder is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row><Grid.Column width={4}>
                                    <label><b>Module</b>&nbsp;<span style={{color: 'red'}}>*</span></label>

                                    <Dropdown
                                        required
                                        name="module"
                                        label="Module"
                                        placeholder="Select Module"
                                        search
                                        selection
                                        validationErrors={{
                                            isDefaultRequiredValue: 'You need to select a module',
                                        }}
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.module}
                                        value={this.state.data.module}
                                        options={modoptions}
                                    />
                                </Grid.Column>
                                    <Grid.Column width={4}>
                                        <label><b>Field Type</b>&nbsp;<span style={{color: 'red'}}>*</span></label>

                                        <Dropdown
                                            required
                                            name="input_type"
                                            label="Input Type"
                                            placeholder="Input Type"
                                            search
                                            selection
                                            validationErrors={{
                                                isDefaultRequiredValue: 'You need to select an Input Type',
                                            }}
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.data.input_type}
                                            value={this.state.data.input_type}
                                            options={itoptions}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row><Grid.Column width={4}>

                                    <Form.Input
                                        required
                                        name="tab"
                                        label="Tab"
                                        placeholder="Tab"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.tab}
                                        value={this.state.data.tab}

                                        validationErrors={{
                                            isDefaultRequiredValue: 'Tab is Required',
                                        }}
                                    />
                                </Grid.Column>
                                    <Grid.Column width={2}>

                                        <Form.Input
                                            required
                                            name="row"
                                            label="Row"
                                            placeholder="Row"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.data.row}
                                            value={this.state.data.row}

                                            validationErrors={{
                                                isDefaultRequiredValue: 'Row is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Form.Input
                                            required
                                            name="col"
                                            label="Column"
                                            placeholder="Column"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.data.col}
                                            value={this.state.data.col}

                                            validationErrors={{
                                                isDefaultRequiredValue: 'Column is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Form.Input
                                            required
                                            name="column_width"
                                            label="Width"
                                            placeholder="Width"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.data.column_width}
                                            value={this.state.data.column_width}

                                            validationErrors={{
                                                isDefaultRequiredValue: 'Column Width is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Form.Input
                                            name="mask"
                                            label="Mask"
                                            placeholder="Mask"
                                            defaultValue={this.state.data.mask}
                                            value={this.state.data.mask}

                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Form.TextArea
                                            name="options"
                                            label="Options"
                                            placeholder="Options"
                                            defaultValue={this.state.data.options}
                                            value={this.state.data.options}

                                        />
                                    </Grid.Column>


                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Form.Input
                                            name="validations"
                                            label="Validations"
                                            placeholder="validations"
                                            defaultValue={this.state.data.validations}

                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Form.TextArea
                                            name="error_message"
                                            label="Error Message"
                                            placeholder="Error Message"
                                            defaultValue={this.state.data.error_message}

                                        />
                                    </Grid.Column>


                                </Grid.Row>
                                <Grid.Row>

                                    <Grid.Column width={12}>
                                        <Form.TextArea
                                            name="description"
                                            label="Field Description"
                                            placeholder="Field Description"
                                            defaultValue={this.state.data.description}

                                        />
                                    </Grid.Column>


                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Tab.Pane>

                )
            }
        ]
        return (
            <div>
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                    <Menu.Item>
                        {current_user.can_update_fields ?
                            <Button size="tiny" color="green" onClick={this.handleSave}>
                                <Icon name='save'/> Save
                            </Button> : ""}
                    </Menu.Item>

                </Menu>
                <br/>
                <Tab renderActiveOnly={false} panes={panes}/>
            </div>

        );
    }
}


