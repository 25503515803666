/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';
import ReactTable from "react-table";
import global from './global'
import {toast} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import {Form, Dropdown, Checkbox} from 'formsy-semantic-ui-react';
import Modal from 'semantic-ui-react/dist/es/modules/Modal';
import Header from 'semantic-ui-react/dist/es/elements/Header';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import Label from 'semantic-ui-react/dist/es/elements/Label';


export class Tables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            showpreview: false


        };

    }

    newRecord = () => {
        this.setState({
            editRecord: [],
            showmodal: true,
            recid: null,
            editname: "New Record"

        });

    }
    updateTable = () => {
        var comp = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: this.state.currentpage,
            sorted: this.state.sorted,
            search: this.state.searchkey
        }

        global.getFetch("tables", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
            });
        })

    }
    handleclose = () => {
        this.setState({showmodal: false})
        this.updateTable()

    }
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({loading: true});
        var srts = ""
        state.sorted.map(function (i) {
            srts += (srts === "" ? "" : ",") + i.id + (i.desc ? " desc" : " asc")
            return srts

        })
        if (srts === "") {
            srts = "ref_table asc,key_value asc"
        }
        var component = this;
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: srts,
            search: this.state.searchkey
        }

        global.getFetch("tables", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize
            });
        })
    }
    handleSave = () => {
         this.refs.editForm.submit()

    }
    refreshtable = () => {
        this.setState({randomkey: Math.random(), searchkey: ""})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    onValidSearchSubmit = (formData) => {
        this.setState({searchkey: formData.search, loading: true, randomkey: Math.random()})

        var component = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: 1,
            sorted: this.state.sorted,
            search: formData.search
        }

        global.getFetch("tables", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                parms: parms
            });
        })
    }
    DeleteEntry = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid
                }
                var component = this
                global.getFetch("tables/delete", parms, component.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        component.notifySuccess(resp.message)
                        var comp = component;
                        var parms = {
                            pageSize: comp.state.pageSize,
                            page: comp.state.currentpage,
                            sorted: comp.state.sorted,
                            search: comp.state.searchkey
                        }

                        global.getFetch("tables", parms, component.state.access_token).then(function (resp) {
                            comp.setState({
                                data: resp.rows,
                                pages: resp.pages,
                                loading: false,
                            });
                        })

                    } else {
                        comp.notifyError(resp.message)

                    }

                })
            }
        })

    }
    onValidSubmit = (formData, resetForm, invalidateForm) => {

        var comp = this
        var parms = formData
        global.postFetch("tables/create", parms, this.state.access_token).then(function (resp) {
            if (resp.success === "Y") {
                comp.notifySuccess(resp.message)
                var parms = {
                    pageSize: comp.state.pageSize,
                    page: comp.state.currentpage,
                    sorted: comp.state.sorted,
                    search: comp.state.searchkey
                }

                global.getFetch("tables", parms, comp.state.access_token).then(function (resp) {
                    comp.setState({
                        data: resp.rows,
                        pages: resp.pages,
                        loading: false,
                    });
                })
                comp.setState({showmodal: false})



            } else {
                comp.notifyError(resp.message)

            }
        })

    }

    render() {
        const {data, pages, pageSize, loading, randomkey} = this.state
        var taboptions = [
            {text: "Contract Class", value: "cclass"},
            {text: "Contract Category", value: "ccat"},
            {text: "Contract Priority", value: "cpri"},
            {text: "Contract Status", value: "cstatus"},

        ]
        return (
            <div>
                <Modal
                    open={this.state.showmodal}
                    size='large' closeIcon
                    closeOnRootNodeClick={true}
                    onClose={this.handleclose}


                >

                    <Header icon='browser' content="New Table Entry"/>

                    <Modal.Content>
                        <Menu>
                            <Menu.Item>
                                <Button size="tiny" color="green" onClick={this.handleSave}>
                                    <Icon name='save'/> Save
                                </Button>
                            </Menu.Item>

                        </Menu> <br/>
                        <Form ref="editForm" onSubmit={this.onValidSubmit}>
                            <Grid padded={false} columns='equal'>
                                <Grid.Row><Grid.Column width={4}>
                                    <label><b>Table</b>&nbsp;<span style={{color: 'red'}}>*</span></label>

                                    <Dropdown
                                        required
                                        name="ref_table"
                                        label="Table"
                                        placeholder="Select Table"
                                        search
                                        selection
                                        validationErrors={{
                                            isDefaultRequiredValue: 'You need to select a table',
                                        }}
                                        errorLabel={<Label color="red" pointing/>}
                                        options={taboptions}
                                    />
                                </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Form.Input
                                            required
                                            name="key_value"
                                            label="Key"
                                            placeholder="Key"
                                            errorLabel={<Label color="red" pointing/>}
                                            validationErrors={{
                                                isDefaultRequiredValue: 'Key is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Form.Input
                                            required
                                            name="value"
                                            label="Value"
                                            placeholder="Value"
                                            errorLabel={<Label color="red" pointing/>}
                                            validationErrors={{
                                                isDefaultRequiredValue: 'Value is Required',
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Menu style={{border: 'none'}}>
                    <Menu.Item style={{border: 'none'}}>

                        <Button size="tiny" icon='write' label="New   " onClick={this.newRecord}/>
                        <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshtable}/>

                    </Menu.Item>
                    <Menu.Menu position='right' style={{border: 'none'}}>
                        <Menu.Item style={{border: 'none'}}>
                            <Button size="tiny" icon="file excel outline" label="Export" onClick={this.exportdata}/>
                        </Menu.Item>
                        <Menu.Item style={{border: 'none'}}>
                            <Form ref="searchform" onValidSubmit={this.onValidSearchSubmit}>
                                <Form.Input name="search" action={{icon: 'search'}} placeholder='Search...'
                                           />
                            </Form>
                        </Menu.Item>
                    </Menu.Menu>

                </Menu>
                <ReactTable
                    key={randomkey}
                    data={data}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onDoubleClick: (e, handleOriginal) => {
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                               if (column.Header==="D" && !rowInfo.row.is_system ) {
                                    this.DeleteEntry(rowInfo.row.id)
                                }
                            }
                        }
                    }}
                    columns={[
                        {
                            Header: "D",
                            Cell: row => (
                                !row.value  ?
                                <div>
                                    <Icon name='delete'/>
                                </div> : <div></div>),
                            width: 30,
                            accessor: "is_system"
                        },

                        {

                            accessor: "id",
                            style: {display: "none"}
                        },

                        {
                            Header: "Table",
                            accessor: "ref_table"
                        },

                        {
                            Header: "Key",
                            accessor: "key_value"
                        }
                        ,
                        {
                            Header: "Value",
                            accessor: "value"
                        }
                    ]}
                    showPaginationTop
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    defaultPageSize={pageSize}
                    className="-striped -highlight"
                />
            </div>

        );
    }
}


