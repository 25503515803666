import React, {Component} from 'react';
import './App.css';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import {Form} from 'formsy-semantic-ui-react';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import Container from 'semantic-ui-react/dist/es/elements/Container';
import {toast} from 'react-toastify';
import global from "./global"

export class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            system_password: this.props.systempass,
            current_user: JSON.parse(sessionStorage.getItem("current_user"))


        };


    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
        sessionStorage.setItem("setupactiveItem", name)

    }
    reload = () => {
        this.setState({randombpid: Math.random()})


    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var data = formData
        var parms = {...data}
        var comp=this
        global.postFetch("users/update_password", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)

                } else {
                    comp.notifyError(resp.message)

                }
            })

    }

    render() {
        const {current_user} = this.state


        return (

            <div><br></br>
                <Container>
                    {this.state.system_password==="Y"?
                        <div style={{color: "red",fontWeight: "bold"}}>Please create a permanent password that you can easily remember. The password must contain at least 8 characters, contain at least 1 digit, and contain upper and lower case characters. </div>

                        : ""
                    }
                    <Grid centered columns={1}>
                        <Form onSubmit={this.onValidSubmit}>
                            <Form.Input name="id" value={current_user.id} readOnly
                                        style={{display: "none"}}/>


                            <Form.Input required name="email" value={current_user.email} readOnly
                                        label='Email Address' placeholder='Email Address'/>
                            <Form.Input required name="password"
                                        type='password' label='Password' placeholder='Password'/>
                            <Form.Input required name="password_confirmation"
                                        type='password' label='Confirm Password' placeholder='Password'/>
                            <Button type='submit'>Submit</Button>
                            <div style={{clear: 'both'}}><span
                                style={{color: 'red'}}>{this.state.errormessage}</span>
                            </div>

                        </Form>

                    </Grid>
                </Container>
            </div>


        );
    }
}

