/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {stateOptions} from "./common"
import MaskedInput from 'react-maskedinput'
import UserFields from "./UserFields"
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import global from "./global"
import {Form, Dropdown, Checkbox} from 'formsy-semantic-ui-react';

export class DepartmentEditRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            users: this.props.users,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            addldata: {},
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            user_fields: this.props.user_fields



        };

    }

    handleSave = () => {

        this.refs.editForm.submit()

    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    errorsOnForm = (model, resetForm, invalidateForm) => {
        toast.error("There are errors on your form! Please review the highlighted fields.", {autoClose: 5000})
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            users: this.props.users,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false

        });
        this.refs.editForm.reset()
    }

    NumberFieldUpdate = (e) => {
        var addldata = this.state.addldata
        addldata[e.target.name] = e.target.value
        this.setState({addldata: addldata})
        this.checkDirty(e.target)

    }

    checkDirty(vals) {
        var savedData = this.state.data
        var comp = this
        Object.keys(vals).map(function (i) {
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false

        })
    }

    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }



    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var data = formData
        var addldata = this.state.addldata
        var comp = this
        var parms = {...data, ...addldata}
        parms.id = this.state.recid
        if (this.state.recid === null) {
            global.postFetch("departments/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        } else {
            global.postFetch("departments/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp
                    })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }

    render() {
        const {current_user} = this.state
        const panes2 = [
            {
                menuItem: 'Main',
                pane: (
                    <Tab.Pane key='tab1'>


                        <Grid padded={false} columns='equal'>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        name="abbreviation"
                                        label="Department Code"
                                        placeholder="Department Code"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.abbreviation}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Department Code is Required',
                                        }}/>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        name="name"
                                        label="Department Name"
                                        placeholder="Department Name"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.name}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Department Name is Required',
                                        }}/>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>

                                    <Form.Input
                                        required
                                        name="address"
                                        label="Address"
                                        placeholder="Address"
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.address}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Address is required',
                                        }}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>

                                    <Form.Input
                                        name="address2"
                                        label="Address 2"
                                        placeholder="Address"
                                        defaultValue={this.state.data.rec.address2}
                                       />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        name="city"
                                        label="City"
                                        required
                                        placeholder="City"
                                        defaultValue={this.state.data.rec.city}
                                    />

                                </Grid.Column>

                                <Grid.Column>

                                    <label><b>State</b></label>

                                    <Dropdown
                                        name="state"
                                        label="State"
                                        placeholder="Select State"
                                        search
                                        selection
                                        width={6}
                                        required
                                        errorLabel={<Label color="red" pointing/>}
                                        defaultValue={this.state.data.rec.state}
                                        options={stateOptions}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        name="zip"
                                        required
                                        label="Zip"
                                        placeholder="Zip"
                                        defaultValue={this.state.data.rec.zip}
                                    />

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>

                                    <label><b>Phone</b></label>
                                    <MaskedInput name="phone" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.phone} mask="(###)###-####"
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <label><b>Fax</b></label>
                                    <MaskedInput name="fax" onChange={this.NumberFieldUpdate}
                                                 value={this.state.data.rec.fax} mask="(###)###-####"
                                    />

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>


                    </Tab.Pane>
                )
            },
            {
                menuItem: 'Users',
                pane: (
                    <Tab.Pane key='tab3'>
                        <label><b>Users</b></label>

                        <Dropdown
                            fluid
                            selection
                            search={true}
                            multiple={true}
                            options={this.state.data.common.users}
                            value={this.state.data.rec.user_depts}
                            placeholder='Add Users'
                            name="user_ids"
                        />
                    </Tab.Pane>
                )
            }

        ]
        const panes=Object.assign(panes2,UserFields(this.state.user_fields,2,this.state.data.rec))

        return (
            <div>
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                    <Menu.Item>
                        {current_user.can_update_departments ?

                        <Button size="tiny" color="green" onClick={this.handleSave}>
                            <Icon name='save'/> Save
                        </Button>
                            : ""}
                    </Menu.Item>

                </Menu>
                <br/>
                <Form onChange={this.markDirty} ref="editForm" onSubmit={this.onValidSubmit}>

                    <Tab renderActiveOnly={false} panes={panes}/>
                </Form>
            </div>

        );
    }
}


