/* eslint array-callback-return: 0 */
import React, {Component} from 'react';
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import global from "./global"
import {Form} from 'formsy-semantic-ui-react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Sortable from 'react-sortablejs';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import Header from 'semantic-ui-react/dist/es/elements/Header';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import Modal from 'semantic-ui-react/dist/es/modules/Modal';
import {confirmAlert} from 'react-confirm-alert';

export class RouteEditRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            cats: this.props.cats,
            dirtyFlag: this.props.dirtyFlag,
            template_save: this.props.data.template,
            confirmOpen: false,
            columns: this.props.columns,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            route: [],
            showroutingmodal: false,
            possible_users: this.props.data.rec.possible_users


        };


    }

    handleEditorChange = (e) => {
        var vals = []
        vals.template = e
        this.checkDirty(vals)
        this.setState({data: {template: e}})
    }
    ChangeApproval = (e, {value}) => {
        var routing = this.state.route
        routing.signatory = value
        this.setState({route: routing,})
    }
    handleSave = () => {

        this.refs.editForm.submit()

    }
    componentWillUpdate = () => {
        this.fixBody();
    }

    componentDidUpdate = () => {
        this.fixBody();
    }
    fixBody = () => {
        const anotherModal = document.getElementsByClassName('ui page modals').length;
        if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
    }
    editApprovalStep = (a, b) => {

        var parms = {
            id: b.id
        }

        global.getFetch("routes/editapprovalstep", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {

                this.setState({
                    route: resp.route,
                    showroutingmodal: true


                })
            } else {
                this.notifyError(resp.message)
            }
        })
    }
    onRoutingValidSubmit = (formData, resetForm, invalidateForm) => {
        var comp = this

        var parms = {...formData, signatory: this.state.route.signatory, route_id: this.state.recid}
        if (!formData.id) {
            global.postFetch("routes/addroute", parms, this.state.access_token).then(resp => {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    var data = comp.state.data
                    data.rec.routing_users = resp.routing_users
                    comp.setState({
                        data: data,
                        showroutingmodal: false

                    })
                } else {
                    comp.notifyError(resp.message)
                }
            })
        } else {
            global.postFetch("routes/updateroute", parms, this.state.access_token).then(resp => {
                if (resp.success === "Y") {
                    this.notifySuccess(resp.message)
                    var data = this.state.data
                    data.rec.routing_users = resp.routing_users
                    this.setState({
                        data: data,
                        showroutingmodal: false
                    })
                } else {
                    this.notifyError(resp.message)
                }
            })
        }
    }
    newApproval = () => {
        var route = this.state.route
        route.signatory = "approval"
        route.id = null
        route.desc = null
        route.user_id = null
        route.locked = false
        route.phase_end = false
        this.setState({route: route, showroutingmodal: true})
    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    handleroutingclose = () => {
        this.setState({showroutingmodal: false})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }

    checkDirty(vals) {
        var savedData = this.state.data
        var comp = this
        Object.keys(vals).map(function (i) {
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false

        })
    }

    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }
    deleteApprovalStep = (a, b) => {


        var parms = {
            id: b.id
        }
        confirmAlert({
                title: 'Confirm Delete',                        // Title dialog
                message: 'Are you sure you want to delete this Approval Step?',               // Message dialog
                confirmLabel: 'Yes',                           // Text button confirm
                cancelLabel: 'No',                             // Text button cancel
                onConfirm: () => {

                    global.getFetch("routes/deleteapprovalstep", parms, this.state.access_token).then(resp => {
                        if (resp.success === "Y") {
                            this.notifySuccess(resp.message)
                            var data = this.state.data
                            data.rec.routing_users = resp.routing_users
                            this.setState({
                                data: data,


                            })
                        } else {
                            this.notifyError(resp.message)
                        }
                    })
                }
            }
        )
    }
    mapOrder = (array, order, key) => {
        var newarray = []
        order.map(function (d) {
                array.map(function (x) {
                    if (parseInt(x["data"][key]) === parseInt(d)) {
                        newarray.push(x)
                    }
                })
            }
        )
        return newarray;
    };
    updateRoutingTree = (tree) => {
        var parms = {tree: tree, default_route_id: this.state.recid}


        global.postFetch("routes/updaterouteorder", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.setState({
                    data: resp.rec,
                })

            }
        })
    }

    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var comp = this
        var parms = formData
        parms.template = this.state.data.template
        parms.id = this.state.recid
        if (this.state.recid === null) {
            global.postFetch("routes/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.dirtyFlag(false)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        columns: resp.columns,
                        possible_users: resp.rec.possible_users
                    })
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        } else {
            global.postFetch("routes/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        columns: resp.columns,
                        possible_users: resp.rec.possible_users
                    })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }

    render() {
        const {current_user, possible_users} = this.state
        var client = window.location.host.split(".")[0]

        
        var soptions = [{text: "", value: "none"}, {text: "County Manager/Administrator", value: "cmanager"},
            {text: "County Attorney", value: "cattorney"},
            {text: "Vendor", value: "vsig"}
        ]
        if(client === 'albany' || client === 'localhost:3000') {
            soptions = [{text: "", value: "none"}, {text: "County Executive", value: "cmanager"},
                {text: "County Attorney", value: "cattorney"},
                {text: "Vendor", value: "vsig"}
            ]
        }
       var panes=[]
        var p = 0
        panes[p] =
            {
                menuItem: 'View',
                pane: (
                    <Tab.Pane key='tab1'>

                        <Form onChange={this.markDirty} ref="editForm" onSubmit={this.onValidSubmit}>
                            <Form.Input
                                required
                                name="name"
                                label="Name"
                                placeholder="Name"
                                errorLabel={<Label color="red" pointing/>}
                                defaultValue={this.state.data.rec.name}
                                validationErrors={{
                                    isDefaultRequiredValue: 'Name is Required',
                                }}
                            />
                            <Form.Dropdown
                                fluid
                                selection
                                search={true}
                                multiple={true}
                                options={this.state.departments}
                                label="Departments"
                                defaultValue={this.state.data.rec.department_id}
                                placeholder='Add Departments'
                                name="department_id"
                            />


                            <Form.TextArea
                                required
                                name="description"
                                label="Route Description"
                                placeholder="Route Description"
                                defaultValue={this.state.data.rec.description}

                                width={16}
                            />

                        </Form>
                    </Tab.Pane>
                )
            }
         if (this.state.recid) {
             p = p + 1
             panes[p] =
                 {
                     menuItem: 'Steps',
                     pane: (
                         <Tab.Pane key='tab2'>
                             <Button size="tiny" color="green" onClick={this.newApproval}>
                                 <Icon name='save'/> New Approval Step
                             </Button>


                             <br/><br/>
                             <Sortable
                                 onChange={(order, sortable, evt) => {
                                     var temparray = this.state.data.rec.routing_users
                                     var newarray = this.mapOrder(temparray, order, "id")
                                     var data = this.state.data
                                     data.rec.routing_users = newarray
                                     this.updateRoutingTree(newarray)
                                     this.setState({data: data})

                                 }} // Defaults to "div"
                             >   {this.state.data.rec.routing_users ?
                                 this.state.data.rec.routing_users.map((d, index) => {
                                     return (
                                         <div className="grabbable" key={d.data.id}
                                              data-id={d.data.id}
                                              style={{width: "500px"}}>
                                             <Segment>
                                                 <div>
                                                     <Button size="tiny" color='red' icon="delete"
                                                             id={"dau-" + d.data.id}
                                                             onClick={this.deleteApprovalStep}></Button>

                                                     <Button
                                                         id={"eau-" + d.data.id} size="tiny" color='green'
                                                         icon="edit"
                                                         onClick={this.editApprovalStep}></Button><b>{index + 1}. {d.user_desc}</b>
                                                 </div>
                                                 <div style={{fontSize: "95%", marginLeft: "85px"}}>{d.data.desc}</div>
                                                 <div style={{
                                                     fontSize: "95%",
                                                     marginLeft: "85px"
                                                 }}>{d.data.signatory.toUpperCase()}</div>
                                             </Segment>

                                         </div>

                                     )
                                 }) : ""}
                             </Sortable>
                         </Tab.Pane>
                     )
                 }
         }
        return (
            <div>
                <Modal
                    open={this.state.showroutingmodal}
                    size='large' closeIcon
                    onClose={this.handleroutingclose}


                >

                    <Header icon='browser' content="Approval Step"/>
                    <Modal.Content>
                        <Form ref="editForm"
                              onSubmit={this.onRoutingValidSubmit}>


                            <Menu>
                                <Menu.Item>

                                    <Button size="tiny" color="green" onClick={this.handleRoutingSave}>
                                        <Icon name='save'/> Save
                                    </Button>
                                </Menu.Item>

                            </Menu><br/>
                            <Grid padded={false} columns='equal'>

                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>
                                    <Grid.Column width={6}>
                                        <Form.Input
                                            name="id"
                                            defaultValue={this.state.route.id ? this.state.route.id.toString() : null}
                                            style={{display: "none"}}
                                        />
                                        <Form.Input
                                            required
                                            name="desc"
                                            size="small"
                                            label="Approval Step Name"
                                            placeholder="Approval Step Name"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.route.desc}

                                            validationErrors={{
                                                isDefaultRequiredValue: 'Approval Step Name is Required',
                                            }}/><label><b>Contract User</b></label>

                                        <Form.Dropdown
                                            fluid
                                            selection
                                            search={true}
                                            multiple={false}
                                            options={possible_users}
                                            value={this.state.route.user_id ? this.state.route.user_id : null}
                                            placeholder='Select User'
                                            name="user_id"

                                        /> <Form.Checkbox
                                        name="vendor_placeholder"
                                        label="Vendor Placeholder"
                                        value={this.state.route.vendor_placeholder}
                                        checked={this.state.route.vendor_placeholder}/><Form.Checkbox
                                        name="department_placeholder"
                                        label="Department Placeholder"
                                        value={this.state.route.department_placeholder}
                                        checked={this.state.route.department_placeholder}

                                    /><Form.Checkbox
                                        name="vendor_signature_placeholder"
                                        label="Vendor Signature Placeholder"
                                        value={this.state.route.vendor_signature_placeholder}
                                        checked={this.state.route.vendor_signature_placeholder}

                                    />
                                        <Form.Checkbox
                                            name="vendor_sig_approve_placeholder"
                                            label="Vendor Approval/Signature Placeholder"
                                            value={this.state.route.vendor_sig_approve_placeholder}
                                            checked={this.state.route.vendor_sig_approve_placeholder}

                                        />
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Segment raised>
                                            <Header as='h5' icon='file outline' content='Approval/Signature'
                                                    style={{paddingBottom: "8px"}}></Header>
                                            <Segment raised>

                                                <Form.Radio
                                                    label='Approval Only'
                                                    name='signatory'
                                                    value='approval'
                                                    onChange={this.ChangeApproval}
                                                    checked={this.state.route.signatory === "approval"}
                                                />
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    multiple={false}
                                                    placeholder='Select Stamp'
                                                    name="approval_role"
                                                    options={soptions}
                                                    value={this.state.route.approval_role ? this.state.route.approval_role : null}


                                                />
                                            </Segment>
                                            <Segment raised>

                                                <Form.Radio
                                                    label='Approval/Signature'
                                                    name='signatory'
                                                    value='signature'
                                                    onChange={this.ChangeApproval}
                                                    checked={this.state.route.signatory === "signature"}
                                                />
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    multiple={false}
                                                    placeholder='Select Stamp'
                                                    name="signature_role"
                                                    options={soptions}
                                                    value={this.state.route.signature_role ? this.state.route.signature_role : null}


                                                />
                                            </Segment>
                                            <Segment raised>

                                                <Form.Radio
                                                    label='Notify Only'
                                                    name='signatory'
                                                    value='notify'
                                                    onChange={this.ChangeApproval}
                                                    checked={this.state.route.signatory === "notify"}
                                                />
                                            </Segment>
                                        </Segment>

                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Segment raised>

                                            <Form.Checkbox
                                                name="locked"
                                                label="Lock after Approval"
                                                value={this.state.route.locked}
                                                defaultChecked={this.state.route.locked}

                                            />
                                            <Form.Checkbox
                                                name="phase_end"
                                                label="Phase End"
                                                value={this.state.route.phase_end}
                                                defaultChecked={this.state.route.phase_end}

                                            />
                                        </Segment>
                                    </Grid.Column>

                                </Grid.Row>

                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>
                                    <Grid.Column width={16}>
                                        <Segment raised>
                                            <Form.TextArea
                                                autoHeight={true}
                                                name="message"
                                                label="Message/Instructions"
                                                placeholder="Message/Instructions"
                                                defaultValue={this.state.route.message}


                                            />
                                        </Segment>
                                    </Grid.Column></Grid.Row>
                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>

                                </Grid.Row>
                            </Grid>

                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleroutingclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                    <Menu.Item>
                        {current_user.can_update_default_routes ?
                            <Button size="tiny" color="green" onClick={this.handleSave}>
                                <Icon name='save'/> Save
                            </Button> : ""}
                    </Menu.Item>

                </Menu>
                <br/>
                <Tab renderActiveOnly={false} panes={panes}/>
            </div>

        );
    }
}

