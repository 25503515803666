/* eslint no-unused-vars: 0 */
import React, {Component} from 'react';
import ReactTable from "react-table";
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import global from './global'
import { toast } from 'react-toastify';


export class BoilerPlateFormats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false

        };

    }
    componentDidMount = () => {
        var comp = this
        var parms = []
        // global.getFetch("bpfformat", parms, this.state.access_token).then(function (resp) {
        //     comp.setState({
        //         data: resp.rows
        //     })

        // });

    }
    refreshtable = () => {
       this.setState({randomkey: Math.random(), searchkey: ""})
    }
    notifyError = (errors) => {
        toast.error(errors+"\n(click anywhere to close)",{autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg,{autoClose: 3000})
    }
    updateControl = (id,val,ff) => {
        var parms = {
            id: id,
            display_name: val,
            field_format: ff


        }
        var comp=this
        global.postFetch("bpfformat/update", parms, this.state.access_token).then(function (resp) {
          
        })
    }
    updateInactive = (id,val) => {
        var parms = {
            id: id,
            inactive: val,


        }
        var comp=this
        global.postFetch("bpfformat/update_inactive", parms, this.state.access_token).then(function (resp) {

        })
    }
    renderEditable = (cellInfo) => {
         return (

            <div
                style={{backgroundColor: "#fafafa"}}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.data];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({data});
                    this.updateControl(cellInfo.original.id,cellInfo.original.display_name,cellInfo.original.field_format)


                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.data[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    renderInactive = (cellInfo) => {
        return (

            <div
                style={{backgroundColor: "#fafafa"}}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.data];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({data});
                    this.updateInactive(cellInfo.original.id,cellInfo.original.inactive)


                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.data[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({loading: true});

        var component = this;
        var srts=""
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: "display_name asc",
            search: this.state.searchkey
        }

        global.getFetch("bpfformat", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize
            });
        })
    }

    render() {
        const {randomkey, data, pages, loading, pageSize} = this.state
        return (
            <div>
                <Menu>
                    <Menu.Item>
                        <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshtable}/>
                    </Menu.Item>

                </Menu>
                <ReactTable
                    key={randomkey}
                    data={data}
                    columns={[
                        {

                            accessor: "id",
                            style: {display: "none"},
                            width: 0
                        },

                        {
                            Header: "Database Field",
                            accessor: "db_field_name"
                        },
                        {
                            Header: "Field Type",
                            accessor: "field_type"
                        },
                        {
                            Header: "Display Name",
                            accessor: "display_name",
                            Cell: this.renderEditable
                        },
                        {
                            Header: "Format",
                            accessor: "field_format",
                            Cell: this.renderEditable
                        }
                        ,
                        {
                            Header: "Inactive",
                            accessor: "inactive",
                            Cell: this.renderInactive
                        }
                    ]}
                    showPaginationTop
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    defaultPageSize={pageSize}
                    className="-striped -highlight"
                />
            </div>

        );
    }
}


