import React, {Component} from 'react';
import Tab from 'semantic-ui-react/dist/es/modules/Tab';

import './App.css';
import {Contracts} from './Contracts'

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            isLoggedIn: this.props.isLoggedIn,
            activeItem: (sessionStorage.getItem("dashactiveItem") ? sessionStorage.getItem("dashactiveItem") : "boilerplates"),
            randombpid: Math.random(),
            randomdpid: Math.random(),
            current_user: JSON.parse(sessionStorage.getItem("current_user")),


        };


    }


    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
        sessionStorage.setItem("dashactiveItem", name)

    }

    render() {
        const {isLoggedIn, access_token, current_user} = this.state

        var panes = []
        var panes2 = [
            {
                menuItem: 'All',
                pane: (<Tab.Pane key="5a"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                  reloadThis={this.reload} cstatus="all"
                                                     path="contracts/user" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Draft',
                pane: (<Tab.Pane key="5b"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                  reloadThis={this.reload} cstatus="draft"
                                                     path="contracts/user" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Executed',
                pane: (<Tab.Pane key="5c"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                  reloadThis={this.reload} cstatus="executed"
                                                     path="contracts/user?status=executed" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Working',
                pane: (<Tab.Pane key="5d"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                  reloadThis={this.reload} cstatus="working"
                                                     path="contracts/user?status=working" key="cu"/></Tab.Pane>)
            }
            ,
            {
                menuItem: 'Archived',
                pane: (<Tab.Pane key="5e"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                  reloadThis={this.reload} cstatus="archived"
                                                     path="contracts/user?status=archived" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Rejected',
                pane: (<Tab.Pane key="5f"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="rejected"
                                                     path="contracts/user?status=rejected" key="cu"/></Tab.Pane>)
            }
            ,
            {
                menuItem: 'Terminated',
                pane: (<Tab.Pane key="5g"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="rejected"
                                                     path="contracts/user?status=terminated" key="cu"/></Tab.Pane>)
            }

        ]
        var panes3 = [
            {
                menuItem: 'All',
                pane: (<Tab.Pane key="6a"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="all"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Draft',
                pane: (<Tab.Pane key="6b"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="draft"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Executed',
                pane: (<Tab.Pane key="6c"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="executed"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Working',
                pane: (<Tab.Pane key="6d"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="working"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            }
            ,
            {
                menuItem: 'Archived',
                pane: (<Tab.Pane key="6e"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="archived"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Rejected',
                pane: (<Tab.Pane key="6f"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="rejected"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            },
            {
                menuItem: 'Terminated',
                pane: (<Tab.Pane key="6g"><Contracts isLoggedIn={isLoggedIn} access_token={access_token}
                                                     reloadThis={this.reload} cstatus="terminated"
                                                     path="contracts/all" key="cu"/></Tab.Pane>)
            }

        ]
        var x = 0
        panes[x] = {
            menuItem: 'Waiting My Action',
            pane: (
                <Tab.Pane key="4">
                    <Contracts cstatus="all" isLoggedIn={isLoggedIn} access_token={access_token}
                               reloadThis={this.reload} path="contracts/userwaiting"
                               key="cuw"/></Tab.Pane>


            )

        }
        x += 1
        panes[x] = {

            menuItem: 'My Contracts', pane:
                (<Tab.Pane key="5">
                    <Tab renderActiveOnly={false} panes={panes2}/>
                </Tab.Pane>)
        }
        if (current_user.can_access_all_contracts) {
            x += 1
            panes[x] =
                {
                    menuItem: 'All Contracts',
                    pane: (<Tab.Pane key="6"><Tab renderActiveOnly={false} panes={panes3}/></Tab.Pane>)
                }
        }

        return (


            <div>
                <Tab renderActiveOnly={false} panes={panes}/>
            </div>
        )
    }
}



