import React, {Component} from 'react';
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import global from "./global"
import {Form} from 'formsy-semantic-ui-react';
import CKEditor from 'react-ckeditor-wrapper'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loader from 'semantic-ui-react/dist/es/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer';
export class BoilerPlateEditRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            cats: this.props.cats,
            dirtyFlag: this.props.dirtyFlag,
            template_save: this.props.data.template,
            confirmOpen: false,
            columns: this.props.columns,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            showdimmer: false


        };


    }
    handleEditorChange = (e) => {
        var vals=[]
        vals.template = e
        this.checkDirty(vals)
        this.setState({data: {template: e}})
    }
    handleSave = () => {

        this.refs.editForm.submit()

    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    notifyError = (errors) => {
        toast.error(errors+"\n(click anywhere to close)",{autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg,{autoClose: 3000})
    }
    checkDirty(vals) {
        var savedData=this.state.data
        var comp=this
        Object.keys(vals).map(function(i){
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false

        })
    }
    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }
    previewPDF = () => {
        var parms = {
            html: this.state.data.template
        }
        global.postFetch("render_pdf", parms, this.state.access_token).then(function (resp) {
            global.openLink(resp.pdf)
        })
    }

    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var comp=this
        var parms=formData
        parms.template=this.state.data.template
        parms.id=this.state.recid
        this.setState({showdimmer: true})
        if (this.state.recid === null) {
            global.postFetch("boilerplates/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.dirtyFlag(false)
                    comp.setState({
                        showdimmer: false,
                        recid: resp.data.id
                    })
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        } else {
            global.postFetch("boilerplates/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.data.id,
                        data: resp.data,
                        columns: resp.columns,
                        showdimmer: false
                    })
                    comp.props.dirtyFlag(false)
                 } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }
    render() {
        const {current_user} = this.state

        const panes = [
            { menuItem: 'View',
                pane: (
                    <Tab.Pane key='tab1'>

                        <Form  onChange={this.markDirty} ref="editForm" onSubmit={this.onValidSubmit}>
                            <Form.Input
                                required
                                name="name"
                                label="Name"
                                placeholder="Name"
                                errorLabel={ <Label color="red" pointing/> }
                                defaultValue={this.state.data.name}
                                validationErrors={{
                                    isDefaultRequiredValue: 'Name is Required',
                                }}
                            />
                            {current_user.can_setup_insertion_template ?

                            <Form.Checkbox
                                defaultChecked={this.state.data.can_insert}
                                toggle
                                name="can_insert"
                                label="Template can be inserted into Template"/> : ""}

                            <Form.Dropdown
                                fluid
                                selection
                                search={true}
                                multiple={true}
                                options={this.state.departments}
                                defaultValue={this.state.data.department_id}
                                placeholder='Add Departments'
                                name="department_id"
                            />

                            <Form.Dropdown
                                name="contract_category"
                                label="Contract Category"
                                placeholder="Select Contract Category"
                                search
                                selection
                                defaultValue={this.state.data.contract_category}

                                options={this.state.cats}
                            />
                            <Form.TextArea
                                required
                                name="description"
                                label="Template Description"
                                placeholder="Template Description"
                                defaultValue={this.state.data.description}

                                width={16}
                             />

                        </Form>
                    </Tab.Pane>
                ) },
            { menuItem: 'Template',
                pane: (
                    <Tab.Pane key='tab2'>
                        <Menu>
                            <Menu.Item position='right'>
                                <Button size="tiny" onClick={this.previewPDF} >
                                    <Icon name='file pdf outline'/> Preview
                                </Button>
                            </Menu.Item>
                        </Menu>
                        <CKEditor
                            value={this.state.data.template}
                            name="template"
                            onChange={this.handleEditorChange}

                            config={{
                                height: 600,
                                availableTokens:
                                   this.state.columns


                            }}/>
                    </Tab.Pane>
                ) }
        ]
        return (
            <div>
                <Dimmer active={this.state.showdimmer}>
                    <Loader size='small' active={this.state.showdimmer}>Working.....</Loader></Dimmer>

                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                <Menu.Item>
                    {current_user.can_update_boilerplates ?
                    <Button  size="tiny" color="green" onClick={this.handleSave}>
                        <Icon name='save'/> Save
                    </Button> : "" }
                </Menu.Item>

            </Menu>
                <br/>
                <Tab renderActiveOnly={false} panes={panes} />
            </div>

        );
    }
}


