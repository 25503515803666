import React, {Component} from 'react';
import './App.css';
import {BoilerPlates} from './BoilerPlates'
import {Departments} from './Departments'
import {Vendors} from './Vendors'
import {Users} from './Users'
import {Controls} from './Controls'
import {Insurances} from './Insurances'
import {Fields} from './Fields'
import {Tables} from './Tables'
import {Emails} from './Emails'
import {Roles} from './Roles'
import {Routes} from './Routes'
import {BoilerPlateFormats} from './BoilerPlateFormats'

import Tab from  'semantic-ui-react/dist/es/modules/Tab';

export class Setup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            isLoggedIn: this.props.isLoggedIn,
            activeItem: (sessionStorage.getItem("setupactiveItem") ? sessionStorage.getItem("setupactiveItem") : "boilerplates"),
            randombpid: Math.random(),
            randomdpid: Math.random(),
            current_user: JSON.parse(sessionStorage.getItem("current_user"))


        };


    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
        sessionStorage.setItem("setupactiveItem", name)

    }
    reload = () => {
        this.setState({randombpid: Math.random()})


    }

    render() {
        const {isLoggedIn, access_token, current_user} = this.state
        var panes = []
        var x = 0
        if (current_user && current_user.can_access_boilerplates) {
            panes[x] = {
                menuItem: 'Templates',
                pane: (<Tab.Pane key="7"> <BoilerPlates isLoggedIn={isLoggedIn} access_token={access_token}
                                                reloadThis={this.reload} current_user={current_user} /></Tab.Pane>)
            }
            x += 1
        }
        if (current_user && current_user.can_access_boilerplates) {
            panes[x] = {
                menuItem: 'Formats',
                pane: (<Tab.Pane key="7"> <BoilerPlateFormats isLoggedIn={isLoggedIn} access_token={access_token}
                                                        reloadThis={this.reload} current_user={current_user} /></Tab.Pane>)
            }
            x += 1
        }
        if (current_user && current_user.can_access_controls) {
            panes[x] = {
                menuItem: 'Controls',
                pane: (<Tab.Pane key="8"><Controls isLoggedIn={isLoggedIn} access_token={access_token}/></Tab.Pane>)
            }
            x += 1
        }
        if (current_user && current_user.can_access_departments) {

            panes[x] = {
                menuItem: 'Departments',
                pane: (<Tab.Pane key="9"> <Departments isLoggedIn={isLoggedIn} access_token={access_token}
                                                       reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_email_templates) {

            panes[x] = {
                menuItem: 'Email\r\nTemplates',
                pane: (<Tab.Pane key="9a"> <Emails isLoggedIn={isLoggedIn} access_token={access_token}
                                                  reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_fields) {

            panes[x] = {
                menuItem: 'Fields',
                pane: (<Tab.Pane key="12"> <Fields isLoggedIn={isLoggedIn} access_token={access_token}
                                                   reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_roles) {

            panes[x] = {
                menuItem: 'Insurance',
                pane: (<Tab.Pane key="12"> <Insurances isLoggedIn={isLoggedIn} access_token={access_token}
                                                   reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_roles) {

            panes[x] = {
                menuItem: 'Roles',
                pane: (<Tab.Pane key="12a"> <Roles isLoggedIn={isLoggedIn} access_token={access_token}
                                                   reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_default_routes) {

            panes[x] = {
                menuItem: 'Routes',
                pane: (<Tab.Pane key="12a"> <Routes isLoggedIn={isLoggedIn} access_token={access_token}
                                                   reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_table_setup) {

            panes[x] = {
                menuItem: 'Tables',
                pane: (<Tab.Pane key="12xx"><Tables isLoggedIn={isLoggedIn} access_token={access_token}
                                                    reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1

        }
        if (current_user && current_user.can_access_users) {

            panes[x] = {
            menuItem: 'Users',
            pane: (<Tab.Pane key="10"><Users isLoggedIn={isLoggedIn} access_token={access_token}
                                    reloadThis={this.reload}/></Tab.Pane>)
            }
            x += 1
        }
        if (current_user && current_user.can_access_vendors) {

            panes[x] =
                {
                    menuItem: 'Vendors',
                    pane: (<Tab.Pane key="11"><Vendors isLoggedIn={isLoggedIn} access_token={access_token}
                                                       reloadThis={this.reload}/></Tab.Pane>)
                }
        }

        return (

            <div>
                <Tab renderActiveOnly={false} panes={panes}/>

            </div>


        );
    }
}

