/* eslint no-eval: 0 */
import React from 'react';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import {Form} from 'formsy-semantic-ui-react';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Tab from 'semantic-ui-react/dist/es/modules/Tab';

var Setfields = (tab, fields, data) => {
    var rows = []

    fields.map(function (name, index) {
        if (name.tab === tab) {
            switch (name.input_type) {
                case 'Input':
                    if (typeof rows[name.row]==="undefined") {
                         rows[name.row]=[]
                    }
                    rows[name.row][name.col]=<Grid.Column key={"col_"+name.db_name} width={eval(name.column_width)}><Form.Input
                        key={"field_"+name.db_name}
                        required={name.is_required ? true : false}
                        defaultValue={!!eval("data.user_data") ? eval("data.user_data." + name.db_name) : ""}
                        name={"user_data." + name.db_name} label={name.label}
                        errorLabel={<Label color="red" pointing/>}
                        placeholder={name.placeholder}
                        validationErrors={{
                            isDefaultRequiredValue: name.name + " is Required",
                        }}
                    /></Grid.Column>;
                    break;
                default:
                    return null;
            }
        }
        return false
    })
    rows.map(function (name,index) {
      rows[index]=<Grid.Row key={"userrow_"+index}>{rows[index]}</Grid.Row>
      return false
    })
    return <Grid padded={false} columns='equal'>{rows}</Grid>

}
var Setfieldsp = (tab, fields, data) => {
     var rows = []

    fields.map(function (name, index) {
        if (name.tab === tab && name.is_active===true) {
            switch (name.input_type) {
                case 'Input':
                    if (typeof rows[name.row]==="undefined") {
                        rows[name.row]=[]
                    }
                    rows[name.row][name.col]=<Grid.Column key={"col_"+name.db_name} width={eval(name.column_width)}><Form.Input
                        key={"field_"+name.db_name}
                        required={name.is_required ? true : false}
                        name={"user_data." + name.db_name} label={name.label}
                        errorLabel={<Label color="red" pointing/>}
                        placeholder={name.placeholder}
                        validationErrors={{
                            isDefaultRequiredValue: name.name + " is Required",
                        }}
                    /></Grid.Column>;
                    break;
                default:
                    return null;
            }
        }
        return false
    })
    rows.map(function (name,index) {
        rows[index]=<Grid.Row key={"userrow_"+index}>{rows[index]}</Grid.Row>
        return false
    })
    return <Grid padded={false} columns='equal'>{rows}</Grid>

}

const UserFields = (ufields, starttab, data) => {


    var lasttab = ""
    var ttab = []
    var i = starttab
    if (ufields) {
        ufields.map(function (name, index) {
                if (name.tab !== lasttab) {
                    ttab[i] = {
                        menuItem: <Menu.Item  key={"ut_" + i}>{name.tab}</Menu.Item>, pane: (<Tab.Pane key={"subtab" + i}>
                            {Setfields(name.tab, ufields, data)}
                        </Tab.Pane>)
                    }
                    i+=1
                }
                lasttab = name.tab
            return false;
            }
        )
    }

    return ttab


}
export const UserFieldsp = (ufields, starttab, module) => {
    var lasttab = ""
    var ttab = []
    var i = starttab
    ufields.map(function (name, index) {
       if (name.module===module && name.is_active===true) {
            if (name.tab !== lasttab) {
                ttab[i] = {
                    menuItem: <Menu.Item key={"ut_" + i}>{name.tab}</Menu.Item>, pane: (<Tab.Pane key={"subtab" + i}>
                        {Setfieldsp(name.tab, ufields)}
                    </Tab.Pane>)
                }
                i+=1
            }
            lasttab = name.tab
        }
        return false
        }
    )
    return ttab


}
export default UserFields


