/* eslint no-unused-vars: 0 */
/* eslint eqeqeq: 0 */
/* eslint no-useless-concat: 0 */
import React, {Component} from 'react';
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import './App.css';
import {Dashboard} from "./Dashboard"
import {Reports} from "./Reports"
import {Setup} from "./Setup"
import {Login} from "./Login"
import {MyAccount} from "./MyAccount"
import ToastContainer from 'react-toastify/lib/components/ToastContainer';
import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import {Vendorsp} from './Vendorsp'
import global from "./global"

const queryString = require('query-string');

class App extends Component {

    state = {
        path: "waiting",
        activeItem: (sessionStorage.getItem("appactiveItem") ? sessionStorage.getItem("appactiveItem") : "dashboard"),
        isLoggedIn: sessionStorage.getItem("isLoggedIn"),
        access_token: sessionStorage.getItem("token"),
        current_user: sessionStorage.getItem("current_user"),
        sso_key: sessionStorage.getItem("sso_key"),
        sso_user: sessionStorage.getItem("sso_user"),
        system_password: sessionStorage.getItem("system_password")
    }
    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
        sessionStorage.setItem("appactiveItem", name)
    }
    setLogin = (val, token, cu, sso,ssou, sp) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('isLoggedIn', val);
        sessionStorage.setItem('current_user', cu)
        sessionStorage.setItem('sso_key', sso)
        sessionStorage.setItem('sso_user', ssou)
        sessionStorage.setItem('system_password', sp)

        this.setState({sso_user: ssou, sso_key: sso, isLoggedIn: val, access_token: token, current_user: cu, system_password: sp})

    }
    getClientInfo = () => {
        console.log("I am testing")
        var parms={}
        global.getFetch("GetClientInfo", parms, this.state.access_token).then(function (resp) {
            document.getElementById("logo").src=resp.logo
            document.getElementById("logo").style.height=50 + 'px'
            document.getElementById("apptitle").innerHTML=resp.title
            sessionStorage.setItem('client_cover', resp.client)

        });


     }


    render() {
        const {isLoggedIn,access_token, current_user, system_password} = this.state
        var cu = JSON.parse(current_user)
        this.getClientInfo()
        var panes = []
        var x = 0
        var fi = 0
        if (cu && cu.can_access_dashboard) {
             panes[x] = {
                 menuItem: <Menu.Item key="mm1">Dashboard</Menu.Item>,
                 pane: (<Tab.Pane key="1"> <Dashboard access_token={access_token}/></Tab.Pane>)
             }
             fi = x
                 x+=1

        }
        if (cu && cu.can_access_setup) {
            panes[x] = {
                menuItem: <Menu.Item key="mm2">Setup</Menu.Item>,
                pane: (<Tab.Pane key="2"> <Setup access_token={access_token}/></Tab.Pane>)
            }
            x+=1
            fi = x


        }
        // if (cu && cu.can_access_reports) {
        //     panes[x] = {
        //         menuItem: <Menu.Item key="mm3">Reports</Menu.Item>,
        //         pane: (<Tab.Pane style={{height: "800px"}} key="3"> <Reports sso_user={this.state.sso_user} sso_key={this.state.sso_key} access_token={access_token}/></Tab.Pane>)
        //     }
        //     x+=1
        //     fi = x


        // }
        if (cu && cu.can_use_vendor_portal) {
            panes[x] = {
                menuItem: <Menu.Item key="mm3">Vendor Info</Menu.Item>,
                pane: (<Tab.Pane style={{height: "800px"}} key="3"> <Vendorsp isLoggedIn={isLoggedIn} access_token={access_token}
                                                                              reloadThis={this.reload}/></Tab.Pane>)
            }
            x+=1
            fi = x


        }
        panes[x] = {
            menuItem: <Menu.Item key="mm9">My Account</Menu.Item>,
            pane: (<Tab.Pane key="39"> <MyAccount systempass={system_password} access_token={access_token}/></Tab.Pane>)
        }

        const parsedHash = queryString.parse(window.location.search);
        if (parsedHash.logoff === "Y") {
            sessionStorage.setItem('token', null);
            sessionStorage.setItem('isLoggedIn', null);
            sessionStorage.setItem('current_user', null);
            window.location = window.location.origin + window.location.pathname;

        }
        var obj = (isLoggedIn !== "null" ? JSON.parse(sessionStorage.getItem("current_user")) : null)
        if (isLoggedIn !== "null" && obj) {
            document.getElementById("user").innerHTML = "Current User: " + obj.email + "<br>"+"&nbsp;&nbsp;&nbsp;<a style='color: white;' target='_blank' href='https://apidev.mycontrack.com/showhelp?cc="+window.location.host.split(".")[0]+"'>Help</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a style='color: white;' href='" + window.location.origin + "/?logoff=Y'>Log Off</a>"
        }

        return (
            <div>

                <ToastContainer
                    position="top-center"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                    style={{zIndex: 99999, whiteSpace: "pre-wrap"}}
                />
                <div>
                    {isLoggedIn !== null && isLoggedIn !== "null" ? <Tab key="mainTab" renderActiveOnly={false} panes={panes} defaultActiveIndex={system_password=="Y" ? x : 0 }/> :
                        <Login onChange={this.setLogin}></Login>}

                </div>


            </div>

        );
    }
}

export default App;
