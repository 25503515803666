import React, {Component} from 'react';
import Iframe from 'react-iframe'

export class Reports extends Component {

    render() {
        return (
            <div style={{height: "100%"}}>
                <Iframe url={"https://dbface-"+window.location.host.split(".")[0]+".mycontrack.com/admin/iframe/?user=test"}
                        id="dbface"
                        display="initial"
                        position="relative"
                        height="100%"
                        allowFullScreen={true}/>
             </div>

        );
    }
}


