var url = require('url')

module.exports = {

    openLink: function (u) {
        var win = window.open(u, "_blank")
        setTimeout(function () {
            if (!win) {
                alert("A pop-up blocker is causing the document to not open. Please disable your pop-up blocker for this URL.");
            }
        }, 1000);


    },
    getFetch: function (route, parms, access_token) {
        return fetch("https://apidev.mycontrack.com/" + route + url.format({query: parms}), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': "Bearer " + access_token,
                    "Client": window.location.host.split(".")[0]
                }
            }
        ).then(function (response) {
            if (response.status === 401 || response.status === 500) {
                alert("An error has been encountered in the following route: " + route + ". Processing will continue.")

            } else {
                return response.json();
            }
        }).then(function (json) {
            return json


        });

    },
    fixDate: function (indate) {
        return indate.substr(5, 2) + "/" + indate.substr(8, 2) + "/" + indate.substr(0, 4)
    },
    postFetch: function (route, parms, access_token) {
        return fetch("https://apidev.mycontrack.com/" + route, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': "Bearer " + access_token,
                    "Client": window.location.host.split(".")[0]
                },
                body: JSON.stringify({
                    parms
                })
            }
        ).then(function (response) {
            if (response.status === 401 || response.status === 500) {
                alert("An error has been encountered in the following route: " + route + ". Processing will continue.")
            } else {
                return response.json();
            }

        }).then(function (json) {
            return json


        });

    },
    postFetchx: function (route, parms, access_token) {
        return fetch("https://apidev.mycontrack.com/" + route, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': "Bearer " + access_token,
                    "Client": window.location.host.split(".")[0]
                },
                body: parms

            }
        ).then(function (response) {
            if (response.status === 401 || response.status === 500) {
                alert("An error has been encountered in the following route: " + route + ". Processing will continue.")
            } else {
                return response.json();
            }

        }).then(function (json) {
            return json


        });

    }

}
