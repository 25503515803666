import React, {Component} from 'react';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import {Form} from 'formsy-semantic-ui-react';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm'
import Modal from 'semantic-ui-react/dist/es/modules/Modal'
import Header from 'semantic-ui-react/dist/es/elements/Header'

import './App.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import global from "./global"
import {UserEditRecord} from "./UserEdit"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {toast} from 'react-toastify';

export class Users extends Component {
     componentWillMount = () =>  {
        this.setState({
            access_token: this.props.access_token,
            isLoggedIn: this.props.isloggedIn,
            data: [],
            pages: null,
            loading: true,
            currentpage: 1,
            randomkey: Math.random(),
            showmodal: false,
            searchkey: "",
            pageSize: 20,
            current_user: JSON.parse(sessionStorage.getItem("current_user"))



        });

    }
    notifyError = (errors) => {
        toast.error(errors+"\n(click anywhere to close)",{autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg,{autoClose: 3000})
    }
    DeleteUser = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid
                }
                var component = this
                global.getFetch("users/delete", parms, component.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        this.notifySuccess(resp.message)
                         var parms = {
                            pageSize: this.state.pageSize,
                            page: this.state.currentpage,
                            sorted: this.state.sorted,
                            search: this.state.searchkey
                        }

                        global.getFetch("users", parms, component.state.access_token).then(resp => {
                            this.setState({
                                data: resp.rows,
                                pages: resp.pages,
                                loading: false,
                            });
                        })

                    } else {
                        this.notifyError(resp.message)

                    }

                })
            }
        })

    }
    loadRecord = (recid) => {
        var parms = {
            id: recid
        }
        var component=this
        global.getFetch("users/read",parms,this.state.access_token).then(function (resp) {
                component.setState({
                editRecord: resp,
                departments: resp.departments,
                showmodal: true,
                recid: recid,
                editname: resp.rec.last_name+", "+resp.rec.first_name,
                    user_fields: resp.user_fields


                });
        })
    }
    handleConfirm = () => {
        this.isDirty = false
        this.updateTable()
        this.setState({confirmOpen: false, showmodal: false})

    }

    handleCancel = () => {
        this.setState({confirmOpen: false})

    }

    onValidSubmit = (formData) => {
        this.setState({searchkey: formData.search, loading: true, randomkey: Math.random()} )

        var component=this;
        var parms = {
            pageSize: this.state.pageSize,
            page: 1,
            sorted: this.state.sorted,
            search: formData.search
        }

        global.getFetch("users",parms,this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                parms: parms
            });
        })
    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
    }

    refreshtable = () => {
        this.refs.searchform.reset()
        this.setState({randomkey: Math.random(), searchkey: ""})
    }

    updateTable = () => {
        var comp = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: this.state.currentpage,
            sorted: this.state.sorted,
            search: this.state.searchkey
        }

        global.getFetch("users", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
            });
        })

    }

    newRecord = () => {
        var comp=this
        var parms=[]
        global.getFetch("users/new",parms,this.state.access_token).then(function (resp) {
            comp.setState({
                editRecord: resp,
                departments: resp.departments,
                showmodal: true,
                recid: null,
                editname: "New Record",
                user_fields: resp.user_fields


            });
        })

    }

    handleclose = () => {
        if (this.isDirty) {
            this.setState({confirmOpen: true})
        } else {
            this.setState({showmodal: false})
            this.updateTable()
        }
    }

    showUser = (rowInfo) => {
        this.isDirty = false
        this.loadRecord(rowInfo.row.id)
    }
    setDirty = (val) => {
         this.isDirty = val
    }
    exportdata = () => {
        var srts = this.state.sorted
        if (srts==="") {
            srts="name asc"
        }
        var parms = {
            pageSize: 99999999,
            page: 1,
            sorted: srts,
            search: this.state.searchkey
        }

        global.getFetch("users/excel",parms,this.state.access_token).then(function (resp) {
           global.openLink('https://apidev.mycontrack.com/showxls?xls='+resp.xls)

        })
    }
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({loading: true});
        var asc = ""
        var srts = ""
        state.sorted.map(function (i) {
            asc = (i.desc ? " desc" : " asc")
            srts += (srts === "" ? "" : ",") + (i.id !== "fullname" ? i.id : "last_name " + asc + ", first_name") + (i.desc ? " desc" : " asc")
            return srts
         })
        if (srts==="") {
            srts="name asc"
        }
        var component=this;
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: srts,
            search: this.state.searchkey
        }

        global.getFetch("users",parms,this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize
            });
        })
    }

    render() {
        const {user_fields,access_token, data, pages, loading, randomkey, recid, editname, editRecord, pageSize, current_user} = this.state

        return (

            <div>
                <Menu style={{border: 'none'}}>
                    <Menu.Item style={{border: 'none'}}>
                        {current_user.can_create_new_users ?

                        <Button size="tiny" icon='write' label="New User" onClick={this.newRecord}/> : " "}
                        <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshtable}/>

                    </Menu.Item>
                    <Menu.Menu position='right' style={{border: 'none'}}>
                        <Menu.Item style={{border: 'none'}}>
                            <Button size="tiny" icon="file excel outline" label="Export" onClick={this.exportdata}/>
                        </Menu.Item>
                        <Menu.Item style={{border: 'none'}}>
                            <Form ref="searchform" onValidSubmit={this.onValidSubmit}>
                                <Form.Input name="search" action={{icon: 'search'}} placeholder='Search...'
                                            onChange={this.handleChange}/>
                            </Form>
                        </Menu.Item>
                    </Menu.Menu>

                </Menu>
                <Modal
                    open={this.state.showmodal}
                    size='large' closeIcon
                    closeOnRootNodeClick={true}
                    onClose={this.handleclose}


                >

                    <Header icon='browser' content={editname}/>
                    <Modal.Content>
                        <Confirm
                            open={this.state.confirmOpen}
                            onCancel={this.handleCancel}
                            onConfirm={this.handleConfirm}
                            content="You have unsaved changes. Are you sure?"
                            style={{fontSize: "1.2rem"}}

                        />

                        <UserEditRecord  user_fields={user_fields} dirtyFlag={this.setDirty} data={editRecord} recid={recid} access_token={access_token} loadRecord={this.loadRecord} updateTable={this.updateTable}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <ReactTable
                    key={randomkey}

                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onDoubleClick: (e, handleOriginal) => {
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.

                                if (column.Header==="D") {
                                    this.DeleteUser(rowInfo.row.id)
                                }
                                if (column.Header==="E") {
                                    this.showUser(rowInfo)

                                }
                            }
                        }
                    }}


                    columns={[
                        {
                            Header: "E",
                            Cell: row => (
                                <div>
                                    <Icon name='edit'/>
                                </div>),
                            width: 30
                        },
                        current_user.can_delete_users ?

                            {
                                Header: "D",
                                Cell: row => (
                                    <div>
                                        <Icon name='delete'/>
                                    </div>),
                                width: 30
                            } :
                            {
                                Header: "",
                                width: 1
                            }
                        ,
                        {
                            Header: "Id",
                            accessor: "id",
                            width: 60
                        },
                        {
                            Header: "Name",
                            accessor: row => row.last_name+", "+row.first_name,
                            id: "fullname"
                        },
                        {
                            Header: "Email",
                            accessor: "email"
                        },
                        {
                            Header: "Address",
                            accessor: "address"
                        },
                                                {
                            Header: "City",
                            accessor: "city"
                        },
                        {
                            Header: "State",
                            accessor: "state"
                        },
                        {
                            Header: "Zip",
                            accessor: "zip"
                        },
                        {
                            Header: "Phone",
                            accessor: "phone"
                        },
                        {
                            Header: "De-Activated",
                            accessor: "deleted"
                        }
                    ]}
                    showPaginationTop
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={data}
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    defaultPageSize={pageSize}
                    className="-striped -highlight"
                />
            </div>


        );
    }
}

