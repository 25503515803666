import React, {Component} from 'react';
import ReactTable from "react-table";
import global from './global'
import {FieldEdit} from "./FieldEdit"
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm';
import {Form} from 'formsy-semantic-ui-react';
import Header from  'semantic-ui-react/dist/es/elements/Header';
import Modal from  'semantic-ui-react/dist/es/modules/Modal';
import { toast } from 'react-toastify';
import UserFieldsp from "./UserFields"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export class Fields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            showpreview: false


        };

    }
    previewScreen = (module) => {
         this.setState({showpreview: true, pmodule: module});

    }
    handleConfirm = () => {
        this.isDirty = false
        this.updateTable()
        this.setState({confirmOpen: false, showmodal: false})

    }

    handleCancel = () => {
        this.setState({confirmOpen: false})

    }
    onValidSubmit = (formData) => {
        this.setState({searchkey: formData.search, loading: true, randomkey: Math.random()})

        var component = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: 1,
            sorted: this.state.sorted,
            search: formData.search
        }

        global.getFetch("fields", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                parms: parms
            });
        })
    }
    showField = (rowInfo) => {
        this.isDirty = false
        this.loadRecord(rowInfo.row.id)

    }
    loadRecord = (recid) => {
        var parms = {
            id: recid
        }
        var component = this
        global.getFetch("fields/read", parms, this.state.access_token).then(function (resp) {
            component.setState({
                editRecord: resp.rec,
                showmodal: true,
                recid: recid,
                editname: resp.rec.name

            });
        })
    }
    notifyError = (errors) => {
        toast.error(errors+"\n(click anywhere to close)",{autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg,{autoClose: 3000})
    }
    componentDidMount = () => {
        var comp = this
        var parms = []
        global.getFetch("fields", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data: resp.rows
            })

        });

    }
    updateTable = () => {
        var comp = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: this.state.currentpage,
            sorted: this.state.sorted,
            search: this.state.searchkey
        }

        global.getFetch("fields", parms, this.state.access_token).then(function (resp) {
            comp.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
            });
        })

    }
    refreshtable = () => {
        this.setState({randomkey: Math.random(), searchkey: ""})
    }
    setDirty = (val) => {
        this.isDirty = val
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    handleclose = () => {
        if (this.isDirty) {
            this.setState({confirmOpen: true})
        } else {
            this.setState({showmodal: false})
            this.updateTable()
        }

    }
    handlepreviewclose = () => {
        this.setState({showpreview: false})

    }
    newRecord = () => {
        this.setState({
            editRecord: [],
            showmodal: true,
            recid: null,
            editname: "New Record"

        });

    }
    DeleteField = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this record?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid
                }
                var component = this
                global.getFetch("fields/delete", parms, component.state.access_token).then(function (resp) {
                    if (resp.success === "Y") {
                        component.notifySuccess(resp.message)
                        var comp = component;
                        var parms = {
                            pageSize: comp.state.pageSize,
                            page: comp.state.currentpage,
                            sorted: comp.state.sorted,
                            search: comp.state.searchkey
                        }

                        global.getFetch("fields", parms, component.state.access_token).then(function (resp) {
                            comp.setState({
                                data: resp.rows,
                                pages: resp.pages,
                                loading: false,
                            });
                        })

                    } else {
                        comp.notifyError(resp.message)

                    }

                })
            }
        })

    }

    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({loading: true});
        var srts = ""
        state.sorted.map(function (i) {
            srts += (srts === "" ? "" : ",") + i.id + (i.desc ? " desc" : " asc")
            return srts

        })
        if (srts === "") {
            srts = "name asc"
        }
        var component = this;
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: srts,
            search: this.state.searchkey
        }

        global.getFetch("fields", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp.rows,
                pages: resp.pages,
                loading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize
            });
        })
    }
    exportdata = () => {
        var srts = this.state.sorted
        if (srts==="") {
            srts="name asc"
        }
        var parms = {
            pageSize: 99999999,
            page: 1,
            sorted: srts,
            search: this.state.searchkey
        }

        global.getFetch("fields/excel",parms,this.state.access_token).then(function (resp) {
            global.openLink('https://apidev.mycontrack.com/showxls?xls='+resp.xls)

        })
    }
    render() {
        const {pmodule,showpreview,columns, editRecord, departments, access_token, data, pages, loading, randomkey, recid, editname, pageSize, current_user} = this.state
        var previewpanes=showpreview ? UserFieldsp(this.state.data,0,pmodule) : null

        return (
            <div>
                <Menu style={{border: 'none'}}>
                    <Menu.Item style={{border: 'none'}}>
                        {current_user.can_create_fields ?
                            <Button size="tiny" icon='write' label="New Field" onClick={this.newRecord}/> : ""}
                        <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshtable}/>

                    </Menu.Item>
                    <Menu.Menu position='right' style={{border: 'none'}}>
                        <Menu.Item style={{border: 'none'}}>
                            <Button size="tiny" icon="file excel outline" label="Export" onClick={this.exportdata}/>
                        </Menu.Item>
                        <Menu.Item style={{border: 'none'}}>
                            <Form ref="searchform" onValidSubmit={this.onValidSubmit}>
                                <Form.Input name="search" action={{icon: 'search'}} placeholder='Search...'
                                            onChange={this.handleChange}/>
                            </Form>
                        </Menu.Item>
                    </Menu.Menu>

                </Menu>
                <Modal
                    open={this.state.showpreview}
                    size='large' closeIcon
                    closeOnRootNodeClick={true}
                    onClose={this.handlepreviewclose}


                >

                    <Modal.Content>
                        <Form>
                        <Tab key="tabtab" renderActiveOnly={false} panes={previewpanes}>
                        </Tab>
                        </Form>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handlepreviewclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.state.showmodal}
                    size='large' closeIcon
                    closeOnRootNodeClick={true}
                    onClose={this.handleclose}


                >

                    <Header icon='browser' content={editname}/>
                    <Modal.Content>
                        <Confirm
                            open={this.state.confirmOpen}
                            onCancel={this.handleCancel}
                            onConfirm={this.handleConfirm}
                            content="You have unsaved changes. Are you sure?"
                            style={{fontSize: "1.2rem"}}

                        />

                        <FieldEdit dirtyFlag={this.setDirty} data={editRecord} departments={departments}
                                   recid={recid} access_token={access_token} loadRecord={this.loadRecord}
                                   updateTable={this.updateTable} columns={columns}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <ReactTable
                    key={randomkey}
                    data={data}

                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onDoubleClick: (e, handleOriginal) => {
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (column.Header==="P") {
                                    this.previewScreen(rowInfo.row.module)
                                }
                                if (column.Header==="E") {
                                    this.showField(rowInfo)
                                }
                                if (column.Header==="D") {
                                    this.DeleteField(rowInfo.row.id)
                                }
                            }
                        }
                    }}
                    columns={[
                         {
                            Header: "P",
                            Cell: row => (
                                <div>
                                         <Icon name='file pdf outline'/>
                                </div>),
                            width: 30
                        },
                        {
                            Header: "E",
                            Cell: row => (
                                <div>
                                    <Icon name='edit'/>
                                </div>),
                            width: 30
                        },
                        current_user.can_delete_fields ?

                            {
                                Header: "D",
                                Cell: row => (
                                    <div>
                                        <Icon name='delete'/>
                                    </div>),
                                width: 30
                            } :
                            {
                                Header: "",
                                width: 1
                            }
                        ,
                        {

                            accessor: "id",
                            width: 60
                        },

                        {
                            Header: "Module",
                            accessor: "module"
                        },

                        {
                            Header: "Tab",
                            accessor: "tab"
                        }
                        ,
                        {
                            Header: "Row",
                            accessor: "row"
                        },
                        {
                            Header: "Column",
                            accessor: "col"
                        },
                        {
                            Header: "Type",
                            accessor: "input_type",

                        },
                        {
                            Header: "Name",
                            accessor: "name",

                        }
                        ,
                        {
                            Header: "DB Name",
                            accessor: "db_name",

                        }
                    ]}
                    showPaginationTop
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    defaultPageSize={pageSize}
                    className="-striped -highlight"
                />
            </div>

        );
    }
}


