import React, {Component} from 'react';
import global from "./global"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import {Form} from 'formsy-semantic-ui-react';
import Label from 'semantic-ui-react/dist/es/elements/Label'
import Accordion from 'semantic-ui-react/dist/es/modules/Accordion'
import Tab from 'semantic-ui-react/dist/es/modules/Tab'
import Confirm from 'semantic-ui-react/dist/es/addons/Confirm'

export class RoleEditRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            addldata: {},
            user_fields: this.props.user_fields,
            activeIndex: []


        };

    }

    handleSave = () => {

        this.refs.editForm.submit()

    }
    handleCancel = () => {
        this.setState({confirmOpen: false})
    }
    handleUndoConfirm = () => {
        this.setState({confirmOpen: false})
        this.props.loadRecord(this.state.recid)
    }
    undoRecord = () => {
        this.setState({confirmOpen: true})
    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    errorsOnForm = (model, resetForm, invalidateForm) => {
        toast.error("There are errors on your form! Please review the highlighted fields.", {autoClose: 5000})
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            access_token: this.props.access_token,
            recid: this.props.recid,
            data: this.props.data,
            departments: this.props.departments,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false

        });
        this.refs.editForm.reset()
    }

    NumberFieldUpdate = (e) => {
        var addldata = this.state.addldata
        addldata[e.target.name] = e.target.value
        this.setState({addldata: addldata})
        this.checkDirty(e.target)

    }

    checkDirty(vals) {
        var savedData = this.state.data.rec
        var comp = this
        Object.keys(vals).map(function (i) {
            if (vals[i] !== savedData[i]) {
                comp.props.dirtyFlag(true)
            }
            return false

        })
    }

    markDirty = (currentValues, isChanged) => {
        this.checkDirty(currentValues)
    }

    handleAccordionClick = (e, titleProps) => {
        const {index,active} = titleProps
        const {activeIndex} = this.state
        if (!active) {
            activeIndex[index] = true
        } else {
            activeIndex[index] = false
        }

        this.setState({activeIndex})
    }


    onValidSubmit = (formData, resetForm, invalidateForm) => {
        var data = formData
        var comp = this
        var parms = {...data}
        parms.id = this.state.recid
        if (this.state.recid === null) {
            global.postFetch("roles/create", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.props.dirtyFlag(false)
                    comp.setState({
                        recid: resp.rec.id,
                        user_fields: resp.user_fields
                    })

                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        } else {
            global.postFetch("roles/update", parms, this.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        user_fields: resp.user_fields
                    })
                    comp.props.dirtyFlag(false)
                } else {
                    comp.notifyError(resp.message)
                    comp.props.dirtyFlag(true)
                }
            })
        }

    }

    render() {
        const {activeIndex} = this.state
         const panes2 = [
            {
                menuItem: 'Main',
                pane: (
                    <Tab.Pane key='tab1'>

                        <Form.Input
                            required
                            name="name"
                            label="Role Name"
                            placeholder="Role Name"
                            errorLabel={<Label color="red" pointing/>}
                            value={this.state.data.rec.name}
                            validationErrors={{
                                isDefaultRequiredValue: 'Role Name is Required',
                            }}/>
                        <Accordion fluid styled>
                            <Accordion.Title active={activeIndex[0]===true} index={0} onClick={this.handleAccordionClick}>
                                <Icon name='dropdown'/>

                                General Security
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex[0]===true}>
                                <Form.Checkbox value={this.state.data.rec.can_access_dashboard} toggle
                                               name="can_access_dashboard" label="User can access Contract Dashboard"/>
                                <Form.Checkbox value={this.state.data.rec.can_access_setup} toggle
                                               name="can_access_setup" label="User can Access Setup Tab"/>
                                <Form.Checkbox value={this.state.data.rec.can_access_reports} toggle
                                               name="can_access_reports" label="User can Access Reports Tab"/>
                                <Form.Checkbox value={this.state.data.rec.limit_selections_to_department} toggle
                                               name="limit_selections_to_department" label="Limit Selections to Assigned Departments"/>
                            </Accordion.Content>
                        </Accordion>

                        <Accordion fluid styled>
                            <Accordion.Title active={activeIndex[1] === true} index={1} onClick={this.handleAccordionClick}>
                                <Icon name='dropdown'/>

                                Setup Security
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeIndex[1] === true || activeIndex[10] === true || activeIndex[11] === true || activeIndex[12] === true  || activeIndex[13] === true || activeIndex[14] === true}>

                                <Form.Checkbox value={this.state.data.rec.can_access_boilerplates} toggle
                                               name="can_access_boilerplates" label="User can access Template Setup"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[11] === true } index={11}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Template Maintenance
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[11] === true}>
                                        <Form.Checkbox value={this.state.data.rec.can_create_boilerplates}
                                                       toggle
                                                       name="can_create_boilerplates"
                                                       label="User can Create New Templates"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_boilerplates}
                                                       toggle
                                                       name="can_delete_boilerplates"
                                                       label="User can Delete Templates"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_boilerplates}
                                                       toggle
                                                       name="can_update_boilerplates"
                                                       label="User can Update Templates"/>
                                        <Form.Checkbox value={this.state.data.rec.can_setup_insertion_template} toggle
                                                       name="can_setup_insertion_template" label="Can Setup Template to be Inserted"/>


                                    </Accordion.Content>

                                </Accordion><br/>
                                <Form.Checkbox value={this.state.data.rec.can_access_controls} toggle
                                               name="can_access_controls" label="User can Access Controls Setup"/>
                                <Form.Checkbox value={this.state.data.rec.can_access_departments} toggle
                                               name="can_access_departments" label="User can Access Departments Setup"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[12] === true} index={12}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Department Maintenance
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[12] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_create_departments}
                                                       toggle
                                                       name="can_create_departments"
                                                       label="User can Create New Departments"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_departments}
                                                       toggle
                                                       name="can_delete_departments"
                                                       label="User can Delete Departments"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_departments}
                                                       toggle
                                                       name="can_update_departments"
                                                       label="User can Update Departments"/>

                                    </Accordion.Content>

                                </Accordion><br/>
                                <Form.Checkbox value={this.state.data.rec.can_access_email_templates} toggle
                                               name="can_access_email_templates"
                                               label="User can Access Email Template Setup"/>

                                <Form.Checkbox value={this.state.data.rec.can_access_fields} toggle
                                               name="can_access_fields" label="User can Access Fields Setup"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[13] === true } index={13}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Field Maintenance
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[13] === true}>
                                        <Form.Checkbox value={this.state.data.rec.can_create_fields} toggle
                                                       name="can_create_fields" label="User can Create New Fields"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_fields} toggle
                                                       name="can_delete_fields" label="User can Delete Fields"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_fields} toggle
                                                       name="can_update_fields" label="User can Update Fields"/>

                                    </Accordion.Content>

                                </Accordion><br/>
                                <Form.Checkbox value={this.state.data.rec.can_access_roles} toggle
                                               name="can_access_roles" label="User can Access Roles Setup"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[133] === true } index={133}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Roles Maintenance
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[133] === true}>
                                        <Form.Checkbox value={this.state.data.rec.can_create_roles} toggle
                                                       name="can_create_roles" label="User can Create New Roles"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_roles} toggle
                                                       name="can_delete_roles" label="User can Delete Roles"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_roles} toggle
                                                       name="can_update_roles" label="User can Update Roles"/>

                                    </Accordion.Content>

                                </Accordion><br/>
                                <Form.Checkbox value={this.state.data.rec.can_access_default_routes} toggle
                                               name="can_access_default_routes" label="User can Access Default Routes Setup"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[1334] === true } index={1334}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Default Routes Maintenance
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[1334] === true}>
                                        <Form.Checkbox value={this.state.data.rec.can_create_default_routes} toggle
                                                       name="can_create_default_routes" label="User can Create New Default Routes"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_default_routes} toggle
                                                       name="can_delete_default_routes" label="User can Delete Default Routes"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_default_routes} toggle
                                                       name="can_update_default_routes" label="User can Update Default Routes"/>

                                    </Accordion.Content>

                                </Accordion><br/>
                                <Form.Checkbox value={this.state.data.rec.can_access_table_setup} toggle
                                               name="can_access_table_setup" label="User can Access Tables Setup"/>

                                <Form.Checkbox value={this.state.data.rec.can_access_users} toggle
                                               name="can_access_users" label="User can Access User Setup"/>
                                <Accordion.Title active={activeIndex[14] === true } index={14}
                                                 onClick={this.handleAccordionClick}>
                                    <Icon name='dropdown'/>
                                    User Maintenance
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex[14] === true}>
                                    <Form.Checkbox value={this.state.data.rec.can_create_new_users} toggle
                                                   name="can_create_new_users" label="User can Create New Users"/>
                                    <Form.Checkbox value={this.state.data.rec.can_delete_users} toggle
                                                   name="can_delete_users" label="User can Delete Users"/>
                                    <Form.Checkbox value={this.state.data.rec.can_update_users} toggle
                                                   name="can_update_users" label="User can Update Users"/>
                                    <Form.Checkbox value={this.state.data.rec.can_only_create_vendor_users} toggle
                                                   name="can_only_create_vendor_users" label="User can Only Create Vendor Users"/>


                                </Accordion.Content>


                                <Form.Checkbox value={this.state.data.rec.can_access_vendors} toggle
                                               name="can_access_vendors" label="User can Access Vendor Setup"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[10] === true} index={10}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Vendor Maintenance
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[10] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_create_new_vendors}
                                                       toggle
                                                       name="can_create_new_vendors"
                                                       label="User can Create New Vendors"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_vendors} toggle
                                                       name="can_delete_vendors" label="User can Delete Vendors"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_vendors} toggle
                                                       name="can_update_vendors" label="User can Update Vendors"/>
                                        <Form.Checkbox value={this.state.data.rec.can_create_vendor_contacts}
                                                       toggle
                                                       name="can_create_vendor_contacts"
                                                       label="User can Create Vendor Contacts"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_vendor_contacts}
                                                       toggle
                                                       name="can_delete_vendor_contacts"
                                                       label="User can Delete Vendor Contacts"/>
                                        <Form.Checkbox value={this.state.data.rec.can_view_vendor_documents}
                                                       toggle
                                                       name="can_view_vendor_documents"
                                                       label="User can View Vendor Documents"/>
                                        <Form.Checkbox value={this.state.data.rec.can_upload_vendor_documents}
                                                       toggle
                                                       name="can_upload_vendor_documents"
                                                       label="User can Upload Vendor Documents"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_vendor_documents}
                                                       toggle
                                                       name="can_delete_vendor_documents"
                                                       label="User can Delete Vendor Documents"/>
                                        <Form.Checkbox value={this.state.data.rec.can_manage_vendor_insurance}
                                                       toggle
                                                       name="can_manage_vendor_insurance"
                                                       label="User can Manage Vendor Insurance"/>
                                        <Form.Checkbox value={this.state.data.rec.can_use_vendor_portal}
                                                       toggle
                                                       name="can_use_vendor_portal"
                                                       label="User can Use the Vendor Portal as a Vendor"/>


                                    </Accordion.Content>

                                </Accordion>
                            </Accordion.Content>

                        </Accordion>
                        <Accordion fluid styled>
                            <Accordion.Title active={activeIndex[30] === true || activeIndex[31] === true || activeIndex[32] === true || activeIndex[33] === true || activeIndex[34] === true || activeIndex[35] === true || activeIndex[36] === true || activeIndex[37] === true || activeIndex[38] === true || activeIndex[39] === true || activeIndex[40] === true} index={30}
                                             onClick={this.handleAccordionClick}>
                                <Icon name='dropdown'/>
                                Contract Security
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex[30] === true || activeIndex[31] === true || activeIndex[32] === true || activeIndex[33] === true || activeIndex[34] === true || activeIndex[35] === true || activeIndex[36] === true || activeIndex[37] === true || activeIndex[38] === true || activeIndex[39] === true || activeIndex[40] === true}>
                                <Form.Checkbox value={this.state.data.rec.can_create_new_contracts}
                                               toggle
                                               name="can_create_new_contracts"
                                               label="User can Create New Contracts"/>
                                <Form.Checkbox value={this.state.data.rec.can_amend_contracts}
                                               toggle
                                               name="can_amend_contracts"
                                               label="User can Amend Contracts"/>
                                <Form.Checkbox value={this.state.data.rec.can_renew_contracts}
                                               toggle
                                               name="can_renew_contracts"
                                               label="User can Renew Contracts"/>
                                <Form.Checkbox value={this.state.data.rec.can_clone_contracts}
                                               toggle
                                               name="can_clone_contracts"
                                               label="User can Clone Contracts"/>


                                <Form.Checkbox value={this.state.data.rec.can_view_contracts}
                                               toggle
                                               name="can_view_contracts"
                                               label="User can View Contracts"/>

                                <Form.Checkbox value={this.state.data.rec.can_delete_contracts}
                                               toggle
                                               name="can_delete_contracts"
                                               label="User can Delete Contracts"/>
                                <Form.Checkbox value={this.state.data.rec.can_edit_contracts}
                                               toggle
                                               name="can_edit_contracts"
                                               label="User can Edit Contracts"/>
                                <Form.Checkbox value={this.state.data.rec.can_unlock_contracts}
                                               toggle
                                               name="can_unlock_contracts"
                                               label="User can Lock or Unlock Contracts"/>
                                <Form.Checkbox value={this.state.data.rec.can_access_all_contracts}
                                               toggle
                                               name="can_access_all_contracts"
                                               label="User can access All Contracts Tab"/>
                                <Form.Checkbox value={this.state.data.rec.can_see_waiting_on}
                                               toggle
                                               name="can_see_waiting_on"
                                               label="User can see the 'Waiting On Column'"/>
                                <Form.Checkbox value={this.state.data.rec.can_not_change_routing}
                                               toggle
                                               name="can_not_change_routing"
                                               label="User can not modify Default Contract Routing"/>
                                <Form.Checkbox
                                    value={this.state.data.rec.can_access_all_contracts_for_assigned_departments}
                                    toggle
                                    name="can_access_all_contracts_for_assigned_departments"
                                    label="User can access All Contracts for assigned departments only"/>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[31] === true} index={31}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Contract Overview Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[31] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_contract_overview_tab}
                                                       toggle
                                                       name="can_access_contract_overview_tab"
                                                       label="User can Access the Contract Overview"/>


                                    </Accordion.Content></Accordion>

                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[32] === true } index={32}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Cover Sheet Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[32] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_cover_sheet_tab}
                                                       toggle
                                                       name="can_access_cover_sheet_tab"
                                                       label="User can Access the Contract Cover Sheet"/>
                                        <Form.Checkbox value={this.state.data.rec.can_designate_paper_contract}
                                                       toggle
                                                       name="can_designate_paper_contract"
                                                       label="User can designate paper contracts"/>

                                        <Form.Checkbox value={this.state.data.rec.can_update_contract_status}
                                                       toggle
                                                       name="can_update_contract_status"
                                                       label="User can update the status of contracts"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_contract_classification}
                                                       toggle
                                                       name="can_update_contract_classification"
                                                       label="User can update the classification of contracts"/>

                                        <Form.Checkbox value={this.state.data.rec.can_update_contract_priority}
                                                       toggle
                                                       name="can_update_contract_priority"
                                                       label="User can update the priority of contracts"/>
                                        <Form.Checkbox value={this.state.data.rec.can_update_contract_category}
                                                       toggle
                                                       name="can_update_contract_category"
                                                       label="User can update the category of contracts"/>


                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[33] === true }index={33}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Financial Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[33] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_financial_tab}
                                                       toggle
                                                       name="can_access_financial_tab"
                                                       label="User can Access the Financial Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_track_encumbrances}
                                                       toggle
                                                       name="can_track_encumbrances"
                                                       label="User can Access Encumbrances Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_create_encumbrances}
                                                       toggle
                                                       name="can_create_encumbrances"
                                                       label="User can Create New Encumbrances"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_encumbrances}
                                                       toggle
                                                       name="can_delete_encumbrances"
                                                       label="User can Delete Encumbrances"/>

                                        <Form.Checkbox value={this.state.data.rec.can_track_expenses}
                                                       toggle
                                                       name="can_track_expenses"
                                                       label="User can Access Expenses Tab"/>

                                        <Form.Checkbox value={this.state.data.rec.can_create_expenses}
                                                       toggle
                                                       name="can_create_expenses"
                                                       label="User can Create New Expenses"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_expenses}
                                                       toggle
                                                       name="can_delete_expenses"
                                                       label="User can Delete Expenses"/>

                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[34] === true } index={34}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Performance Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[34] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_performance_tab}
                                                       toggle
                                                       name="can_access_performance_tab"
                                                       label="User can Access the Performance Tab"/>

                                        <Form.Checkbox value={this.state.data.rec.can_create_new_objectives}
                                                       toggle
                                                       name="can_create_new_objectives"
                                                       label="User can Create New Objectives"/>

                                        <Form.Checkbox value={this.state.data.rec.can_edit_objectives}
                                                       toggle
                                                       name="can_edit_objectives"
                                                       label="User can Edit Objectives"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_objectives}
                                                       toggle
                                                       name="can_delete_objectives"
                                                       label="User can Delete Objectives"/>
                                        <Form.Checkbox value={this.state.data.rec.can_create_new_measures}
                                                       toggle
                                                       name="can_create_new_measures"
                                                       label="User can Create New Measurements"/>

                                        <Form.Checkbox value={this.state.data.rec.can_edit_measures}
                                                       toggle
                                                       name="can_edit_measures"
                                                       label="User can Edit Measurements"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_measures}
                                                       toggle
                                                       name="can_delete_measures"
                                                       label="User can Delete Measurements"/>


                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[35] === true } index={35}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Access Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[35] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_access_tab}
                                                       toggle
                                                       name="can_access_access_tab"
                                                       label="User can Access the Access Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_add_users_to_contracts}
                                                       toggle
                                                       name="can_add_users_to_contracts"
                                                       label="User can Add Users to Access"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_users_from_contracts}
                                                       toggle
                                                       name="can_delete_users_from_contracts"
                                                       label="User can Delete Users From Access"/>



                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[36] === true } index={36}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Routing Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[36] === true}>
                                        <Form.Checkbox value={this.state.data.rec.can_access_routing_tab}
                                                       toggle
                                                       name="can_access_routing_tab"
                                                       label="User can Access the Routing Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_create_new_approval_steps}
                                                       toggle
                                                       name="can_create_new_approval_steps"
                                                       label="User can Create New Approval Steps"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_approval_steps}
                                                       toggle
                                                       name="can_delete_approval_steps"
                                                       label="User can Delete Approval Steps"/>
                                        <Form.Checkbox value={this.state.data.rec.can_edit_approval_steps}
                                                       toggle
                                                       name="can_edit_approval_steps"
                                                       label="User can Edit Approval Steps"/>
                                        <Form.Checkbox value={this.state.data.rec.can_start_routing}
                                                       toggle
                                                       name="can_start_routing"
                                                       label="User can Start Routing"/>
                                        <Form.Checkbox value={this.state.data.rec.can_reset_routing}
                                                       toggle
                                                       name="can_reset_routing"
                                                       label="User can Reset Routing"/>

                                        <Form.Checkbox value={this.state.data.rec.can_redirect_routing}
                                                       toggle
                                                       name="can_redirect_routing"
                                                       label="User can Redirect Routing"/>



                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[37] === true } index={37}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Document Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[37] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_contract_documents_tab}
                                                       toggle
                                                       name="can_access_contract_documents_tab"
                                                       label="User can Access the Documents Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_access_document_templates}
                                                       toggle
                                                       name="can_access_document_templates"
                                                       label="User can Access the Templates Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_access_document_contract}
                                                       toggle
                                                       name="can_access_document_contract"
                                                       label="User can Access the Contract Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_delete_contract_document}
                                                       toggle
                                                       name="can_delete_contract_document"
                                                       label="User can Delete Generated Contract Documents"/>

                                        <Form.Checkbox value={this.state.data.rec.can_access_document_insurance}
                                                       toggle
                                                       name="can_access_document_insurance"
                                                       label="User can Access the Insurance Tab"/>
                                        <Form.Checkbox value={this.state.data.rec.can_set_insurance_limits}
                                                       toggle
                                                       name="can_set_insurance_limits"
                                                       label="User can Set Contract Insurance Limits"/>

                                        <Form.Checkbox value={this.state.data.rec.can_delete_contract_insurance}
                                                       toggle
                                                       name="can_delete_contract_insurance"
                                                       label="User can Delete Contract Insurance Documents"/>

                                        <Form.Checkbox value={this.state.data.rec.can_import_contract_insurance}
                                                       toggle
                                                       name="can_import_contract_insurance"
                                                       label="User can Import Contract Insurance Documents"/>

                                        <Form.Checkbox value={this.state.data.rec.can_access_document_attachments}
                                                       toggle
                                                       name="can_access_document_attachments"
                                                       label="User can Access the Attachments Tab"/>




                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[38] === true } index={38}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Messages Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[38] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_messages_tab}
                                                       toggle
                                                       name="can_access_messages_tab"
                                                       label="User can Access the Messages Tab"/>

                                    </Accordion.Content></Accordion>
                                <Accordion fluid styled>
                                    <Accordion.Title active={activeIndex[39] === true } index={39}
                                                     onClick={this.handleAccordionClick}>
                                        <Icon name='dropdown'/>
                                        Activity Security
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex[39] === true }>
                                        <Form.Checkbox value={this.state.data.rec.can_access_activity_tab}
                                                       toggle
                                                       name="can_access_activity_tab"
                                                       label="User can Access the Activity Tab"/>


                                    </Accordion.Content></Accordion>
                            </Accordion.Content>
                        </Accordion>
                    </Tab.Pane>
                )
            }
        ]
        const panes = panes2

        return (
            <div>
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleUndoConfirm}
                    content="Are you sure you want to undo changes?"
                    style={{fontSize: "1.2rem"}}

                />
                <Menu>
                    <Menu.Item>
                        <Button size="tiny" color="green" onClick={this.handleSave}>
                            <Icon name='save'/> Save
                        </Button>
                    </Menu.Item>


                </Menu>
                <br/>
                <Form onChange={this.markDirty} ref="editForm" onSubmit={this.onValidSubmit}>

                    <Tab renderActiveOnly={false} panes={panes}/>
                </Form>
            </div>

        );
    }
}


